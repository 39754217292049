<!--
 * @Author: 张博洋
 * @Date: 2021-10-25 10:10:02
 * @LastEditTime: 2021-11-11 11:53:47
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/check/index.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="flex flex-column">
    <div class="banner">
      <img :src="headPic"
           alt="">
    </div>

    <div class="intro">
      <div class="head">{{subTitle}}</div>
      <div class="con">
        <h1>测评介绍</h1>
        <p v-html="description"></p>
      </div>
    </div>

    <div class="btn-group">
      <van-button style="width:100%"
                  type="primary"
                  @click="patientIsShow = true"
                  round>开始测评</van-button>

    </div>

    <PatientListPanel :list="list"
                      :patientIsShow="patientIsShow"
                      @closePatient="closePatient"
                      @closePanel="closePanel"></PatientListPanel>
  </div>
</template>

<script>
import PatientListPanel from '@/components/patient/patientListPanel.vue'
export default {
  data () {
    return {
      description: '',
      subTitle: '',
      list: [],
      patientIsShow: false,
      headPic: ''
    }
  },
  components: {
    PatientListPanel
  },
  created () {
    if (this.$route.query.questionnaireId == '1') {
      this.$.setTitle('焦虑自评表')
    } else if (this.$route.query.questionnaireId == '2') {
      this.$.setTitle('抑郁自评表')
    }
    this.getQueryQuestionsDetail()
    this.getPatientList()
  },
  activated () {
    this.getPatientList()
  },
  methods: {
    getQueryQuestionsDetail () {
      this.$axios({
        type: 'post',
        url: "question/queryQuestionnaireDetail",
        data: {
          questionnaireId: this.$route.query.questionnaireId
        },
        elseData: {
          loading: true
        },
        _self: this
      }).then(res => {
        console.log(res)
        this.description = res.d.description
        this.subTitle = res.d.subTitle
        this.headPic = res.d.headPic
      })
    },
    getPatientList () {
      this.$axios({
        url: "healthy/getPatients",
        _self: this
      }).then(res => {
        console.log(res)
        this.list = [...res.d.data]
      })
    },
    closePatient (payload) {
      console.log(payload)
      this.patientIsShow = false
      this.$router.push({
        name: 'check.detail',
        query: {
          patientId: payload.patientId,
          questionnaireId: this.$route.query.questionnaireId
        }
      })
    },
    closePanel (payload) {
      console.log(payload)
      this.patientIsShow = false
    },

  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 160px;
  background: rgba(26, 179, 112, 1);
  img {
    width: 100%;
  }
}
// .banner2 {
//   width: 100%;
//   height: 160px;
//   background: #1ab370 url('../../static/img/check-banner-2.png') no-repeat;
//   background-size: 100%;
// }
.intro {
  margin: -20px 8px 0;
  border-radius: 8px;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  .head {
    font-size: 12px;
    height: 44px;
    line-height: 44px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff8200;
    text-align: center;
    background: linear-gradient(270deg, #fffdf5 0%, #fffbe8 98%);
  }
  .con {
    background: #fff;
    padding: 24px 16px;
    overflow: auto;
    h1 {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 21px;
      text-align: center;
    }
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      margin-top: 25px;
      line-height: 1.5;
      white-space: pre-line;
    }
  }
}
.btn-group {
  height: 84px;
  background: #ffffff;
  padding: 10px 20px 0;
}
</style>